import { Avatar, Box, Button, Checkbox, CircularProgress, Container, Divider, Grid, InputAdornment, OutlinedInput, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { GoHome } from 'react-icons/go';
import Footer from '../components/Footer';
import { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetPassword } from '../apis/WithLoginAPI';
import Header from '../components/Header';
import { Colors } from '../constant/Colors';
import { forgotValidationSchema } from '../constant/formikValidation';

const ForgotPassword = () => {
    const navigate = useNavigate()
    const [verifyEmail, setVerifyEmail] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleReset = async (values) => {
        setLoading(true)
        try {
            const res = await resetPassword(values.email)
            toast.success(res.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
            setTimeout(() => {
                navigate('/updatepassword')
            }, 2500);
        } catch (error) {
            console.log("🚀 ~ file: Login.jsx:30 ~ handleLogin ~ error:", error)
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setLoading(false)
        }
    }

    const initialValues = { email: '' }

    return (
        <div>
            <Header />

            <Grid container spacing={2} px={{ xs: 2, md: 18 }} sx={{ marginTop: '10px', justifyContent: 'center' }}>
                <Grid item xs={12} md={7} mt={8} sx={{ backgroundColor: Colors.white, borderRadius: '10px', padding: '10px' }}>
                    <Typography
                        sx={{ fontSize: '36px', color: Colors.Primary }}>
                        Forgot
                        <span style={{ color: 'black', fontWeight: '300' }}> Password?</span>
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }} >Enter the email associated with your account and we'll send an email with instructions to reset your password.</Typography>
                    {!verifyEmail && (
                        <>
                            <Formik
                                initialValues={{
                                    email: '', // Add other form fields if needed
                                }}
                                validationSchema={forgotValidationSchema}
                                onSubmit={handleReset}
                            >
                                {({ errors, touched, validateForm, handleSubmit }) => (
                                    <Form>
                                        {/* Use Field component for form fields */}
                                        <Stack mt={4}>
                                            <Field
                                                type="text"
                                                as={OutlinedInput}
                                                sx={{ marginTop: 2, backgroundColor: '#F6F6F6' }}
                                                placeholder='Email'
                                                id="email"
                                                name="email"
                                                aria-describedby="outlined-weight-helper-text"
                                                inputProps={{
                                                    'aria-label': 'weight',
                                                    style: {
                                                        fontSize: '14px',
                                                    },
                                                }}
                                            />
                                        </Stack>
                                        <ErrorMessage name="email" component="div" style={{ color: 'red', marginTop: '5px', fontSize: '12px' }} />

                                        <Button
                                            onClick={loading ? '' : handleSubmit}
                                            id="basic-button"
                                            aria-haspopup="true"
                                            sx={{
                                                marginTop: '15px',
                                                backgroundColor: loading ? 'white' : Colors.Primary, '&:hover': {
                                                    backgroundColor: loading ? 'white' : Colors.Primary,
                                                },
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {loading ? <CircularProgress size={12} color={'warning'} /> : <Typography fontSize={'16px'} fontFamily={"Roboto"} color={'white'} >Submit</Typography>}
                                        </Button>
                                    </Form>
                                )}
                            </Formik>
                            {/* <Stack mt={4}>
                                <OutlinedInput
                                    sx={{ marginTop: 2, backgroundColor: '#F6F6F6' }}
                                    placeholder='Email'
                                    id="outlined-adornment-weight"
                                    // endAdornment={<IoKeyOutline color='#ADADAD' size={20} />}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                        style: {
                                            fontFamily: 'Roboto, sans-serif', // Change 'YourCustomFont' to the desired font family
                                            fontSize: '14px', // Adjust the font size as needed
                                        },
                                    }}
                                />
                            </Stack>
                            <Button
                                onClick={handleVerifyEmail}
                                id="basic-button"
                                aria-haspopup="true"
                                sx={{
                                    marginTop: '15px',
                                    backgroundColor: Colors.Primary, '&:hover': {
                                        backgroundColor: Colors.Primary,
                                    },
                                    textTransform: 'capitalize'
                                }}
                            >
                                <Typography fontSize={'12px'} fontFamily={"Roboto"} color={'white'} >Send Instructions</Typography>
                            </Button> */}
                            {/* <Divider sx={{ marginTop: 5 }} />
                            <Typography onClick={() => navigate('/signup')} fontSize={'12px'} fontFamily={"Roboto"} color={'#333333'} mt={'10px'} >
                                Dont’t have an Account?
                                <span style={{
                                    marginLeft: '5px',
                                    color: Colors.Primary, textDecorationLine: 'underline',
                                    cursor: 'pointer',
                                }}
                                >
                                    Sign Up
                                </span>
                            </Typography> */}
                        </>
                    )}

                    {/* {verifyEmail && (
                        <>
                            <Box
                                gap={1}
                                sx={{
                                    border: '1px solid #67BB2F',
                                    borderColor: '#67BB2F',
                                    borderRadius: '5px',
                                    p: '20px',
                                    marginTop: '10px',
                                    display: 'flex',
                                    alignItems: 'center'
                                }} >
                                <Avatar variant='square' src={appImages.emailVerify} />
                                <Box>
                                    <Typography sx={{ fontSize: '14px', color: '#67BB2F', fontFamily: 'Roboto' }} >Check your email</Typography>
                                    <Typography sx={{ fontSize: '12px', fontWeight: '300', fontFamily: 'Roboto' }} >We have sent a password recover instructions to your associated email.</Typography>
                                </Box>
                            </Box>
                            <Typography sx={{ marginTop: 3, fontWeight: '300' }}>Did not receive the email? Check your spam filter, or</Typography>
                            <Typography sx={{ fontFamily: 'Roboto' }}>Try another email address</Typography>
                        </>
                    )} */}

                </Grid>
                <Divider sx={{ display: { xs: 'none', md: 'block' }, height: '50vh', marginTop: 5 }} orientation="vertical" variant="middle" flexItem />
                {/* <Grid item xs={12} md={5} px={5} >
                    <Typography pt={5} >Login via social media</Typography>
                    <Button
                        id="basic-button"
                        aria-haspopup="true"
                        sx={{
                            marginTop: '15px',
                            backgroundColor: Colors.fbColor, '&:hover': {
                                backgroundColor: Colors.fbColor,
                            },
                            textTransform: 'capitalize',
                            paddingLeft: 2,
                            width: '250px'
                        }}
                    >
                        <FaFacebookF color='white' size={'20px'} />
                        <Typography
                            width={'250px'}
                            fontSize={'12px'}
                            fontFamily={"Roboto"}
                            color={'white'}
                            textAlign={'left'}
                            marginLeft={1}
                        >Sign in with Facebook</Typography>
                    </Button>
                    <br />
                    <Button
                        variant='outlined'
                        id="basic-button"
                        aria-haspopup="true"
                        sx={{
                            marginTop: '15px',
                            backgroundColor: Colors.white, '&:hover': {
                                backgroundColor: Colors.white,
                            },
                            textTransform: 'capitalize',
                            paddingLeft: 2,
                            width: '250px',
                            borderColor: Colors.borderColor, // Set the border color to black
                            '&:hover': {
                                borderColor: Colors.borderColor, // Change the border color on hover
                            },
                        }}
                    >
                        <FcGoogle color='white' size={'20px'} />
                        <Typography
                            width={'250px'}
                            fontSize={'12px'}
                            fontFamily={"Roboto"}
                            color={Colors.black}
                            textAlign={'left'}
                            marginLeft={1}
                        >Sign in with Gmail</Typography>
                    </Button>
                </Grid> */}
            </Grid>
            <Footer />
            <ToastContainer />
        </div>
    )
}

export default ForgotPassword