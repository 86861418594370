import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
});

const validateConfirmPassword = (value, { parent }) => {
    return value !== parent.Password ? undefined : 'Passwords do not match';
};

export const signUpValidationSchema = Yup.object().shape({
    Name: Yup.string().required('Name is required'),
    Email: Yup.string().email('Invalid email address').required('Email is required'),
    Password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    // ConfirmPassword: Yup.string()
    //     .required('Confirm Password is required')
    //     .test('passwords-match', 'Passwords do not match', validateConfirmPassword),
    // ConfirmPassword: Yup.string().required(' Confirm password is required'),
    // plan: Yup.string().required('Plan is required'),
});

export const forgotValidationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is Required'),
});

export const updatePasswordSchema = Yup.object({
    ResetCode: Yup.string().required('ResetCode Required'),
    Password: Yup.string().required('Password is required').min(8, 'Password contain atleast 8 characters'),
    ConfirmedPassword: Yup.string().required('ConfirmedPassword is required').min(8, 'Password contain atleast 8 characters'),
});