
import './App.css';
import { AllRoutes } from './pages/Routes';


function App() {
  return (
   <AllRoutes/>
  );
}

export default App;
