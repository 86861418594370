export const audio = {
    Abbi: require("../samples/Abbi.wav"),
    Alfie: require("../samples/Alfie.wav"),
    Andrew: require("../samples/Andrew.wav"),
    Bella: require("../samples/Bella.wav"),
    Clara: require("../samples/Clara.wav"),
    Duncan: require("../samples/Duncan.wav"),
    Ethan: require("../samples/Ethan.wav"),
    Imani: require("../samples/Imani.wav"),
    James: require("../samples/James.wav"),
    Kim: require("../samples/Kim.wav"),
    Liam: require("../samples/Liam.wav"),
    Libby: require("../samples/Libby.wav"),
    Luna: require("../samples/Luna.wav"),
    Molly: require("../samples/Molly.wav"),
    Natasha: require("../samples/Natasha.wav"),
    Neil: require("../samples/Neil.wav"),
    Olivia: require("../samples/Olivia.wav"),
    Ryan: require("../samples/Ryan.wav"),
    Sam: require("../samples/Sam.wav"),
    Tina: require("../samples/Tina.wav"),
  };
  
