import React from 'react';
import { Container, Grid, Typography, Link, Box, Divider } from '@mui/material';
import { appImages } from '../assets/css/images';



const Footer = () => {

    return (
        <footer>
            <Container sx={{ marginTop: '5%' }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                        <div>
                            <img src={appImages.logoWhite} alt="TextifyAudio Logo" style={{ width: '150px' }} />
                            <Typography sx={{ color: 'white', fontSize: '18px', fontWeight: '700', }}>
                                Unlock the Power of Text and Audio Conversion
                            </Typography>
                            <Typography sx={{ color: 'white', fontSize: '16px', }}>
                                Quickly convert text to speech or speech to text in multiple languages, including English, French, German,
                                Hindi, Hebrew, Mandarin, and many more! Our cutting-edge technology ensures fast and accurate conversions,
                                breaking language barriers and enhancing accessibility. Discover the possibilities with{' '}
                                <Link href="https://textifyaudio.com" sx={{ fontSize: '18px' }}>TextifyAudio.com</Link>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div>
                            <Typography sx={{ color: 'white', fontSize: '24px', fontWeight: '700', marginBottom: 1.5 }}>
                                Text To Speech
                            </Typography>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">English USA text to speech</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">English UK text to speech</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">English Australian text to speech</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">German text to speech</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">French text to speech</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">Hindi text to speech</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">Urdu text to speech</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">Italian text to speech</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">South African text to speech</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">Mendrian text to speech</Link>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div>
                            <Typography sx={{ color: 'white', fontSize: '24px', fontWeight: '700', marginBottom: 1.5 }}>
                                Speech To Text
                            </Typography>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">English USA speech to text</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">English UK speech to text</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">English Australian speech to text</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">German speech to text</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">French speech to text</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">Hindi speech to text</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">Urdu speech to text</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">Italian speech to text</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">South African speech to text</Link>
                            </Box>
                            <Box sx={{ marginBottom: 1 }}>
                                <Link sx={{ color: 'white', fontSize: '18px', '&:hover': { textDecorationLine: 'none', marginLeft: 1, color: '#ff9966' }, transition: 'color 0.5s' }} href="#">Mendrian speech to text</Link>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </Container>
            <Divider sx={{ borderColor: 'white', pt: 5 }} />
            <Box sx={{ mt: 3, marginBottom: 2, px: '10%' }}>
                <Typography variant="body2" color="textSecondary" component="p" sx={{ color: 'white' }}>
                    © 2024 <Link href="#">TextifyAudio.com</Link> All Rights Reserved.
                </Typography>
            </Box>

        </footer>
    );
};

export default Footer;
