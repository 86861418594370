import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetUpgradePackage } from '../apis/WithLoginAPI'
import Header from '../components/Header'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Colors } from '../constant/Colors'
import SampleAudios from '../components/SampleAudios'
import Footer from '../components/Footer'

const VerifyPayment = () => {
    const navigate = useNavigate()
    const userData = JSON.parse(localStorage.getItem("loggedUser"))

    useEffect(() => {
        (async () => {
            const formData = new FormData();
            formData.append("plan", "Monthly");
            const response = await GetUpgradePackage(userData.token, formData);
            console.log("Response from GetUpgradePackage:", response);
            // toast.success(response.message, {
            //     position: "top-right",
            //     autoClose: 2500,
        //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            // });
            // setTimeout(() => {
            //     navigate("/");
            // }, 2500);
        })()
    }, [])


    return (
        <>
            <Header />
            <Grid container justifyContent="center">
                <Grid item xs={12} md={10} sx={{ marginTop: 5, backgroundColor: 'white', padding: 4, borderRadius: '10px' }}>
                    <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 2 }} >
                        Thank You!
                    </Typography>
                    <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 2 }} >
                        Payment Succesfully
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: 2 }}>
                        <Button sx={{ backgroundColor: Colors.Primary, fontWeight: '700' }} onClick={() => navigate('/')} variant="contained" >
                            Back to Home
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <SampleAudios />

            <Footer />
            <ToastContainer />
        </>
    )
}

export default VerifyPayment