import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, TextField, FormControl, Button, Autocomplete, Box, Backdrop, CircularProgress, LinearProgress } from '@mui/material';
import Header from '../components/Header';
import SampleAudios from '../components/SampleAudios';
import Footer from '../components/Footer';
import { TextToSpeechWithoutLogin } from '../apis/WithoutLogin';
import axios from 'axios';
import { getAllLanuages, TextToSpeechWithLogin, getAllVoiceByLang } from '../apis/WithLoginAPI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha';

import { FaPlayCircle } from "react-icons/fa";
import { PlayArrow, Pause } from '@mui/icons-material';
import { Colors } from '../constant/Colors';
import { useLocation } from 'react-router-dom';


const DownloadPage = () => {
    const { state } = useLocation()

    const userData = JSON.parse(localStorage.getItem("loggedUser"))

    const [currentAudio, setCurrentAudio] = useState(null);
    const audio = {}; // Initialize an object to store audio elements

    const togglePlay = (key) => {
        const audioElement = audio[key];
        const audioSrc = process.env.REACT_APP_API_URL_DOWNLOAD_FILE_BASIC + state.params;

        if (!audioElement) {
            console.error("Audio element not found.");
            return;
        }

        if (audioElement?.paused) {
            if (currentAudio !== null) {
                audio[currentAudio]?.pause();
            }
            audioElement.src = audioSrc; // Set audio source
            audioElement?.play();
            setCurrentAudio(key);
        } else {
            audioElement?.pause();
            setCurrentAudio(null);
        }
    };

    const handleAudioEnded = () => {
        setCurrentAudio(null); // Reset currentAudio when audio ends
    };

    return (
        <div style={{ backgroundColor: '#151937', height: 'auto', marginTop: '8%' }}>
            <Header />
            <Container>
                {/* <Grid container justifyContent="center">
                    <Grid item xs={12} md={10} sx={{ marginTop: 5, backgroundColor: 'white', padding: 4, borderRadius: '10px', minHeight: '300px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ width: '100%' }}>
                            <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 2 }} >
                                Your file is ready to download
                            </Typography>
                            <Box sx={{ textAlign: 'center', marginRight: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <audio ref={(ref) => { audio['audio'] = ref; }} style={{ display: 'none' }}>
                                        <source src={process.env.REACT_APP_API_URL_DOWNLOAD_FILE_BASIC + state.params} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                    <Button sx={{ backgroundColor: Colors.Primary, fontWeight: '700', width: '30%' }} onClick={() => togglePlay('audio')} variant="contained" color="primary" >
                                        {currentAudio === 'audio' && !audio['audio']?.paused ? <Pause /> : <FaPlayCircle size={24} color={'white'} style={{ marginRight: '5px' }} />}
                                        <FaPlayCircle size={24} color={'white'} style={{ marginRight: '5px' }} /> Play
                                    </Button>
                                </Box>

                            </Box>
                        </Box>
                    </Grid>
                </Grid> */}
                <Grid container justifyContent="center" >
                    <Grid item xs={12} md={10} sx={{ marginTop: 5, backgroundColor: 'white', padding: 4, borderRadius: '10px', minHeight: '300px', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ width: '100%' }}>
                            <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 2 }} >
                                Your file is ready to download
                            </Typography>
                            {!userData?.user ?
                                <Box sx={{ textAlign: 'center', marginRight: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Button sx={{ backgroundColor: Colors.Primary, fontWeight: '700', width: '30%' }} onClick={() => window.open(process.env.REACT_APP_API_URL_DOWNLOAD_FILE_BASIC + state.params)} variant="contained" color="primary" >
                                        <FaPlayCircle size={24} color={'white'} style={{ marginRight: '5px' }} /> Download
                                    </Button>
                                </Box>
                                :
                                <Box sx={{ textAlign: 'center', marginRight: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Button sx={{ backgroundColor: Colors.Primary, fontWeight: '700', width: '30%' }} onClick={() => window.open(userData.user.plan == 'Monthly' ? process.env.REACT_APP_API_URL_DOWNLOAD_FILE_MONTHLY + state.params : process.env.REACT_APP_API_URL_DOWNLOAD_FILE_BASIC + state.params)} variant="contained" color="primary" >
                                        <FaPlayCircle size={24} color={'white'} style={{ marginRight: '5px' }} /> Download
                                    </Button>
                                </Box>
                            }
                            <Box sx={{ textAlign: 'center', marginTop: 1 }}>
                                <audio ref={(ref) => { audio['audio'] = ref; }} style={{ display: 'none' }} onEnded={handleAudioEnded}>
                                    <source src={process.env.REACT_APP_API_URL_DOWNLOAD_FILE_BASIC + state.params} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                </audio>
                                <Button sx={{ backgroundColor: Colors.Primary, fontWeight: '700', width: '30%' }} onClick={() => togglePlay('audio')} variant="contained" color="primary" >
                                    {currentAudio === 'audio' && !audio['audio']?.paused ? <Pause /> : <FaPlayCircle size={24} color={'white'} style={{ marginRight: '5px' }} />}
                                    {currentAudio === 'audio' && !audio['audio']?.paused ? 'Pause' : 'Play'}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>

            <SampleAudios />

            <Footer />

            <ToastContainer />
        </div>
    );
};

export default DownloadPage;