import React, { useState } from 'react'
import { Container, Grid, Typography, Box, createTheme, ThemeProvider } from '@mui/material';
import { audio } from '../assets/samples';
import { PlayArrow, Pause } from '@mui/icons-material';


const theme = createTheme({
    typography: {
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

const SampleAudios = () => {
    const [currentAudio, setCurrentAudio] = useState(null);

    const togglePlay = (key, audioElement) => {
        // if (audioElement.paused) {
        //     if (currentAudio !== null) {
        //         audio[currentAudio].pause();
        //     }
        //     audioElement.play();
        //     setCurrentAudio(key);
        // } else {
        //     audioElement.pause();
        //     setCurrentAudio(null);
        // }
        if (currentAudio === key) {
            // Pause the audio if it's already playing
            audio[key].pause();
            setCurrentAudio(null);
        } else {
            // Stop the currently playing audio and play the new one
            if (currentAudio !== null) {
                audio[currentAudio].pause();
            }
            audio[key].play();
            setCurrentAudio(key);
        }
    };

    const handleAudioEnded = () => {
        setCurrentAudio(null); // Reset currentAudio when audio ends
    };

    return (
        <ThemeProvider theme={theme}>
            <Container sx={{ marginTop: '6%' }} >
                <Typography sx={{ fontSize: '34px', fontWeight: '700', marginBottom: 5, color: 'white' }}>Sample Audios</Typography>
                <Grid container sx={{ paddingBottom: 1 }} >
                    {Object.keys(audio).map((key) => (
                        <Grid item xs={12} sm={12} md={3} key={key}>
                            <Box className="SampleAudioGrid">
                                <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                    <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 2, fontWeight: '700', fontSize: '18px' }}>{key}</Typography>
                                    <audio ref={(ref) => { audio[key] = ref; }} style={{ width: '250px' }} onEnded={handleAudioEnded}>
                                        <source src={audio[key]} type="audio/mpeg" />
                                        Your browser does not support the audio element.
                                    </audio>
                                    <button style={{ width: '90%' }} onClick={() => togglePlay(key, audio[key])}>
                                        {currentAudio === key && !audio[key].paused ? <Pause /> : <PlayArrow />}
                                    </button>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
                {/* <Grid container sx={{ paddingBottom: 1 }} >
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Abbi</Typography>
                                <audio controls src={audio.Abbi} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Alfie</Typography>
                                <audio controls src={audio.Alfie} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Andrew</Typography>
                                <audio controls src={audio.Andrew} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Bella</Typography>
                                <audio controls src={audio.Bella} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                </Grid>
                <Grid container sx={{ paddingBottom: 1 }} >
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Clara</Typography>
                                <audio controls src={audio.Clara} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Duncan</Typography>
                                <audio controls src={audio.Duncan} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Ethan</Typography>
                                <audio controls src={audio.Ethan} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Imani</Typography>
                                <audio controls src={audio.Imani} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                </Grid>
                <Grid container sx={{ paddingBottom: 1 }} >
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>James</Typography>
                                <audio controls src={audio.James} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Kim</Typography>
                                <audio controls src={audio.Kim} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Liam</Typography>
                                <audio controls src={audio.Liam} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Libby</Typography>
                                <audio controls src={audio.Libby} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                </Grid>
                <Grid container sx={{ paddingBottom: 1 }} >
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Luna</Typography>
                                <audio controls src={audio.Luna} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Molly</Typography>
                                <audio controls src={audio.Molly} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Natasha</Typography>
                                <audio controls src={audio.Natasha} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Neil</Typography>
                                <audio controls src={audio.Neil} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                </Grid>
                <Grid container sx={{ paddingBottom: '5%' }} >
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Olivia</Typography>
                                <audio controls src={audio.Olivia} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Ryan</Typography>
                                <audio controls src={audio.Ryan} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Sam</Typography>
                                <audio controls src={audio.Sam} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box className="SampleAudioGrid">
                            <Box sx={{ position: 'relative', textAlign: 'center', }}>
                                <Typography sx={{ mt: 1, mb: 1, color: 'white', textAlign: 'left', marginLeft: 6, fontWeight: '700', fontFamily: 'Roboto', fontSize: '20px' }}>Tina</Typography>
                                <audio controls src={audio.Tina} style={{ width: '250px' }}>
                                    Your browser does not support the audio element.
                                </audio>
                            </Box>,
                        </Box>
                    </Grid>
                </Grid> */}
            </Container>
        </ThemeProvider>
    )
}

export default SampleAudios