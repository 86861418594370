import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Header from '../components/Header';
import { Container, Grid, Typography, TextField, Button, IconButton, InputAdornment, Box, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Colors } from '../constant/Colors';
import { loginValidationSchema, signUpValidationSchema } from '../constant/formikValidation';
import { loginUser, registerUser } from '../apis/WithLoginAPI';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUp = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('')

    const initialValues = {
        Name: '',
        Email: '',
        Password: '',
        Plan: 'Basic'
    };

    const handleSignUp = async (values) => {
        if (values.Password.trim() === confirmPassword.trim()) {
            try {
                const res = await registerUser(values)
                console.log("🚀 ~ handleSignUp ~ res:", res)
                toast.success(res.message, {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setTimeout(() => {
                    navigate("/otp", { state: { token: res.data.token } })
                }, 1500);
            } catch (error) {
                console.log("🚀 ~ file: Login.jsx:30 ~ handleLogin ~ error:", error.response.data)
                toast.error(error.response.data.message, {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            toast.error("Password not match", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const handleTogglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(prevState => !prevState);
    };

    return (
        <>
            <Header />
            <Container>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={6} sx={{ marginTop: '8%', marginBottom: '8%', backgroundColor: 'white', padding: 4, borderRadius: '10px' }}>
                        <Typography sx={{ textAlign: 'center', marginBottom: 2, fontSize: '28px', fontWeight: '700', }}>
                            SignUp
                        </Typography>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={signUpValidationSchema}
                            onSubmit={handleSignUp}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <Field
                                        as={TextField}
                                        type="text"
                                        name="Name"
                                        label="Name"
                                        fullWidth
                                        margin="normal"
                                    />
                                    <ErrorMessage name="Name" render={msg => <div style={{ color: 'red', fontSize: '14px' }}>{msg}</div>} />

                                    <Field
                                        as={TextField}
                                        type="email"
                                        name="Email"
                                        label="Email"
                                        fullWidth
                                        margin="normal"
                                    />
                                    <ErrorMessage name="Email" render={msg => <div style={{ color: 'red', fontSize: '14px' }}>{msg}</div>} />

                                    <Field
                                        as={TextField}
                                        type={showPassword ? 'text' : 'password'}
                                        name="Password"
                                        label="Password"
                                        fullWidth
                                        margin="normal"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <ErrorMessage name="Password" render={msg => <div style={{ color: 'red', fontSize: '14px' }}>{msg}</div>} />

                                    {/* <Field
                                        as={TextField}
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        name="ConfirmPassword"
                                        label="Confirm Password"
                                        fullWidth
                                        margin="normal"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleConfirmPasswordVisibility} edge="end">
                                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    /> */}
                                    <Field
                                        as={TextField}
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        name="ConfirmPassword"
                                        label="Confirm Password"
                                        fullWidth
                                        margin="normal"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleConfirmPasswordVisibility} edge="end">
                                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    {/* <ErrorMessage name="ConfirmPassword" render={msg => <div style={{ color: 'red', fontSize: '14px' }}>{msg}</div>} /> */}

                                    {/* <Field
                                        as={TextField}
                                        type="text"
                                        name="plan"
                                        label="Plan"
                                        fullWidth
                                        margin="normal"
                                    />
                                    <ErrorMessage name="plan" render={msg => <div style={{ color: 'red', fontSize: '14px' }}>{msg}</div>} /> */}

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={isSubmitting}
                                        sx={{ marginTop: 2, backgroundColor: !isSubmitting ? Colors.Primary : Colors.white, '&:hover': { backgroundColor: Colors.Primary }, fontSize: '18px', fontWeight: '700', }}
                                    >
                                        {isSubmitting ? <CircularProgress size={28} color={'warning'} /> : 'SignUp'}
                                    </Button>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2, justifyContent: 'center' }} >
                                        <Typography sx={{}}>Already have an account</Typography>
                                        <Link
                                            to={'/login'}
                                            disabled={isSubmitting}
                                            style={{ fontSize: '14px', fontWeight: '700', '&:hover': { backgroundColor: 'white' }, marginLeft: '5px', }}
                                        >
                                            Sign In
                                        </Link>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
            <ToastContainer />
        </>
    );
};

export default SignUp;