import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Divider, useMediaQuery, Grid, Avatar, Button, Box, ThemeProvider, createTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { appImages } from '../assets/css/images';
import { useLocation, useNavigate } from 'react-router-dom';


const theme = createTheme({
  typography: {
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  ListItemText: {
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

const Header = () => {
  const matches = useMediaQuery('(max-width:820px)');
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname

  const userData = JSON.parse(localStorage.getItem("loggedUser"))

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{ marginTop: '8%' }}>
        {matches ?
          <AppBar position="static">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" >
                TextifyAudio
              </Typography>
            </Toolbar>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
              <div
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <List>
                  {['Home', 'Speech To Text', 'About', 'Pricing', 'Contact Us'].map((text, index) => (
                    <ListItem button key={text}>
                      <ListItemText primary={text} />
                    </ListItem>
                  ))}
                </List>
                <Divider />
                <List>
                  <ListItem button>
                    <ListItemText primary="Speech To Text" />
                  </ListItem>
                </List>
              </div>
            </Drawer>
          </AppBar>
          :
          <Grid sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 999,
            display: 'flex',
            backgroundColor: '#151937',
            justifyContent: 'space-around',
            alignItems: 'center',
            px: 5,
          }}>
            <Avatar sx={{ width: 200, height: 100, '& .MuiAvatar-img': { objectFit: 'fill' }, }} src={appImages.logoWhite} />
            <Button
              onClick={() => navigate('/')}
              sx={{
                fontSize: '22px',
                color: pathname === '/' ? '#ff9966' : 'white',
                textDecorationLine: pathname === '/' ? 'underline' : '',
                transition: 'color 0.5s',
                '&:hover': { color: '#ff9966', textDecorationLine: 'underline', backgroundColor: '#151937' },
                cursor: 'pointer',

              }}>
              <Typography >
                Home
              </Typography>
            </Button>
            <Button
              onClick={() => navigate('/speechtotext')}
              sx={{
                fontSize: '22px',
                color: pathname === '/speechtotext' ? '#ff9966' : 'white',
                textDecorationLine: pathname === '/speechtotext' ? 'underline' : '',
                transition: 'color 0.5s',
                '&:hover': { color: '#ff9966', textDecorationLine: 'underline', backgroundColor: '#151937' },
                cursor: 'pointer',

              }}>
              <Typography >
                Speech To Text
              </Typography>
            </Button>
            <Button
              onClick={() => navigate('')}
              sx={{
                fontSize: '22px',
                color: pathname === '' ? '#ff9966' : 'white',
                textDecorationLine: pathname === '' ? 'underline' : '',
                transition: 'color 0.5s',
                '&:hover': { color: '#ff9966', textDecorationLine: 'underline', backgroundColor: '#151937' },
                cursor: 'pointer',

              }}>
              <Typography >
                About
              </Typography>
            </Button>
            <Button
              onClick={() => navigate('')}
              sx={{
                fontSize: '22px',
                color: pathname === '' ? '#ff9966' : 'white',
                textDecorationLine: pathname === '' ? 'underline' : '',
                transition: 'color 0.5s',
                '&:hover': { color: '#ff9966', textDecorationLine: 'underline', backgroundColor: '#151937' },
                cursor: 'pointer',

              }}>
              <Typography >
                Pricing
              </Typography>
            </Button>
            <Button
              onClick={() => navigate('')}
              sx={{
                fontSize: '22px',
                color: pathname === '' ? '#ff9966' : 'white',
                textDecorationLine: pathname === '' ? 'underline' : '',
                transition: 'color 0.5s',
                '&:hover': { color: '#ff9966', textDecorationLine: 'underline', backgroundColor: '#151937' },
                cursor: 'pointer',

              }}>
              <Typography  >
                Contact Us
              </Typography>
            </Button>
            <Box>
              <Button
                onClick={() => navigate('/speechtotext')}
                id="basic-button"
                aria-haspopup="true"
                sx={{
                  backgroundColor: 'white', '&:hover': {
                    backgroundColor: 'white'
                  },

                }}
              >
                <Typography
                  sx={{
                    color: 'black',
                    fontWeight: '700',
                  }} >Speech To Text</Typography>
              </Button>
              {!userData?.user ?
                <Button
                  onClick={() => navigate('/login')}
                  id="basic-button"
                  aria-haspopup="true"
                  sx={{
                    // backgroundColor: 'white', '&:hover': {
                    //   backgroundColor: 'white'
                    // },
                    marginLeft: '5px'
                  }}
                >
                  <Typography
                    sx={{
                      color: 'white',
                      fontWeight: '700',
                      textDecorationLine: 'underline'
                    }} >Login</Typography>
                </Button>
                :
                <Button
                  onClick={() => {
                    localStorage.removeItem("loggedUser")
                    setTimeout(() => {
                      document.location.reload(navigate("/"));
                    }, 200);
                  }}
                  id="basic-button"
                  aria-haspopup="true"
                  sx={{
                    // backgroundColor: 'white', '&:hover': {
                    //   backgroundColor: 'white'
                    // },
                    marginLeft: '5px'
                  }}
                >
                  <Typography
                    sx={{
                      color: 'white',
                      fontWeight: '700',
                      textDecorationLine: 'underline'
                    }} >Logout</Typography>
                </Button>
              }
            </Box>
          </Grid>
        }
      </div>
    </ThemeProvider>
  );
};

export default Header;