import { AxiosInstance } from './AxiosInstance';

export const loginUser = async (credentials) => {
    const response = await AxiosInstance.post('/Login', { ...credentials });
    localStorage.setItem("loggedUser", JSON.stringify(response.data.data));
    return response.data;
};

export const registerUser = async (credentials) => {
    const response = await AxiosInstance.post('/Register', { ...credentials });
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${response.data.data.token}`;
    // localStorage.setItem("loggedUser", JSON.stringify(response.data.data));
    return response.data;
};

export const verificationOTP = async (otp) => {
    const response = await AxiosInstance.get('/VerifyOTP/' + otp);
    return response.data;
};

export const resendOTP = async (token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/ResendOTP');

    return response.data;
};

export const resetPassword = async (email) => {
    const response = await AxiosInstance.get('/ResetPassword/' + email);
    return response.data;
};

export const updatePassword = async (ResetCode, Password, ConfirmedPassword) => {
    const response = await AxiosInstance.post('/UpdatePassword', { ResetCode, Password, ConfirmedPassword });
    return response.data;
};

export const getAllLanuages = async () => {
    const response = await AxiosInstance.get('/getLanguages');
    return response.data;
};

export const getUserDetail = async (token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.get('/UserDetail');
    return response.data;
};


export const getAllVoiceByLang = async (lang) => {
    const response = await AxiosInstance.get('/getLanguagesVoices/' + lang);
    return response.data;
};

export const TextToSpeechWithLogin = async (token, data) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/textToAudio', data);
    return response.data;
};

export const GetVerifyPayment = async (token) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/verifyPayment');
    return response.data;
};

export const GetUpgradePackage = async (token, data) => {
    const headers = AxiosInstance.defaults.headers;
    headers['Authorization'] = `Bearer ${token}`;
    const response = await AxiosInstance.post('/upgradePlan', data);
    return response.data;
};

export const SpeechToTextWithLogin = async (data) => {
    const headers = AxiosInstance.defaults.headers;
    headers["Content-Type"] = "multipart/form-data";
    const response = await AxiosInstance.post('/audioToText', data);
    return response.data;
};