import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, TextField, FormControl, Button, Autocomplete, Box, Backdrop, CircularProgress, LinearProgress, Avatar } from '@mui/material';
import Header from '../components/Header';
import SampleAudios from '../components/SampleAudios';
import Footer from '../components/Footer';
import { TextToSpeechWithoutLogin } from '../apis/WithoutLogin';
import axios from 'axios';
import { getAllLanuages, TextToSpeechWithLogin, getAllVoiceByLang, getUserDetail } from '../apis/WithLoginAPI';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha';

import { FaPlayCircle } from "react-icons/fa";
import { Colors } from '../constant/Colors';
import { useNavigate } from 'react-router-dom';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ReCaptchaComponent = () => {
  const { executeRecaptcha } = useGoogleReCaptcha(); // Assuming you are using a hook to access Recaptcha

  const handleVerifyRecaptcha = React.useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Recaptcha has not been loaded');
      return;
    }

    const token = await executeRecaptcha('home');
    console.log("🚀 ~ handleVerifyRecaptcha ~ token:", token)
    // You can do something with the token here, like sending it to your server
  }, [executeRecaptcha]);

  return (
    <div>
      <button onClick={handleVerifyRecaptcha} style={{ display: 'none' }}>Verify Recaptcha</button>
    </div>
  );
};


const Home = () => {
  const userData = JSON.parse(localStorage.getItem("loggedUser"))

  const navigate = useNavigate()

  const [text, setText] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(); // Default language
  const [selectedVoice, setSelectedVoice] = useState();
  const [voice, setVoice] = useState()
  const [captchaValue, setCaptchaValue] = useState()
  const [loading, setLoading] = useState(false);
  // const [withLoginData, setWithLoginData] = useState('')
  const [withoutLoginData, setWithoutLoginData] = useState('')
  const [allLanguages, setAllLanguages] = useState()

  const [userDetail, setUserDetail] = useState()

  useEffect(() => {
    (async () => {
      if (userData?.user) {
        const res = await getUserDetail(userData?.token)
        console.log("🚀 ~ res: 38", res)
        setUserDetail(res.data)
      }
    })()
  }, [])



  const handleLanguageChange = (e) => {
    setSelectedLanguage(e?.title);
    setSelectedVoice(null);
    handeGetAllVoices(e?.title)
    // You may want to load voices based on selected language here
  };


  const languageList = [
    { title: "English" },
    { title: "Afrikaans" },
    { title: "Arabic" },
    { title: "Armenian" },
    { title: "Azerbaijani" },
    { title: "Belarusian" },
    { title: "Bosnian" },
    { title: "Bulgarian" },
    { title: "Catalan" },
    { title: "Chinese" },
    { title: "Croatian" },
    { title: "Czech" },
    { title: "Danish" },
    { title: "Dutch" },
    { title: "Estonian" },
    { title: "Finnish" },
    { title: "French" },
    { title: "Galician" },
    { title: "German" },
    { title: "Greek" },
    { title: "Hebrew" },
    { title: "Hindi" },
    { title: "Hungarian" },
    { title: "Icelandic" },
    { title: "Indonesian" },
    { title: "Italian" },
    { title: "Japanese" },
    { title: "Kannada" },
    { title: "Kazakh" },
    { title: "Korean" },
    { title: "Latvian" },
    { title: "Lithuanian" },
    { title: "Macedonian" },
    { title: "Malay" },
    { title: "Marathi" },
    { title: "Maori" },
    { title: "Nepali" },
    { title: "Norwegian" },
    { title: "Persian" },
    { title: "Polish" },
    { title: "Portuguese" },
    { title: "Romanian" },
    { title: "Russian" },
    { title: "Serbian" },
    { title: "Slovak" },
    { title: "Slovenian" },
    { title: "Spanish" },
    { title: "Swahili" },
    { title: "Swedish" },
    { title: "Tagalog" },
    { title: "Tamil" },
    { title: "Thai" },
    { title: "Turkish" },
    { title: "Ukrainian" },
    { title: "Urdu" },
    { title: "Vietnamese" },
    { title: "Welsh" }
  ];

  // useEffect(() => {
  //   handeGetAllVoices(selectedLanguage)
  // }, [selectedLanguage])

  const handeGetAllVoices = async (lang) => {
    setLoading(true)
    try {
      const res = await getAllVoiceByLang(lang)
      setSelectedVoice(res.data.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName)).map((item) => {
        return {
          name: item?.DisplayName,
          sendName: item?.Name
        }
      }))
      setLoading(false)
    } catch (error) {
      console.log("🚀 ~ handeGetAllVoices ~ error:", error)
      setLoading(false)
    }
  }

  const handeGetAllLanguages = async () => {
    const res = await getAllLanuages()
    let entries = Object.entries(res);
    setAllLanguages()
  }

  const handleVoiceChange = (item) => {
    setVoice(item?.sendName);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmitWithoutLogin = async () => {
    if (!selectedLanguage) {
      toast.error("Please select a language", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!voice) {
      toast.error("Please select a voice", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("text", text)
      formData.append("voice", voice)
      const res = await TextToSpeechWithoutLogin(formData)
      setWithoutLoginData(res.data)
      // navigate('/download', {state:{params:{res.data}})}
      navigate("/download", {
        state: {
          params: res.data,
        }
      })
      setLoading(false)
      toast.success(res?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error)
      setLoading(false)
    }
  };

  const handleSubmitWithLogin = async () => {
    if (!selectedLanguage) {
      toast.error("Please select a language", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!voice) {
      toast.error("Please select a voice", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("text", text)
      formData.append("voice", voice)
      const res = await TextToSpeechWithLogin(userData.token, formData)
      // setWithLoginData(res.data)
      navigate("/download", {
        state: {
          params: res.data,
        }
      })
      setLoading(false)
      toast.success(res?.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // window.open(process.env.REACT_APP_API_URL_DOWNLOAD_FILE + res.data)
    } catch (error) {
      console.log("🚀 ~ handleSubmit ~ error:", error)
      setLoading(false)
    }
  };

  const handleStripeForm = () => {
    navigate('/stripe')
  }

  return (
    <div style={{ backgroundColor: '#151937', height: 'auto', marginTop: '8%' }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box sx={{ width: '30%' }}>
          <Typography sx={{ textAlign: 'center', marginLeft: '10px', fontSize: '18px', fontWeight: '700' }}>Loading ...</Typography>
          <LinearProgress color="inherit" />
        </Box>
      </Backdrop>
      <Header />
      <Container>
        <Grid container justifyContent="center" >
          {userDetail?.plan == 'Basic' &&
            <Grid item md={10} sx={{ cursor: 'pointer' }}>
              <Avatar variant='rounded' sx={{ width: '100%', height: '200px' }} onClick={handleStripeForm} />
            </Grid>
          }
          <Grid item xs={12} md={10} sx={{ marginTop: 5, backgroundColor: 'white', padding: 4, borderRadius: '10px' }}>
            <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 2 }} >
              Fastest Text-To-Speech Conversion.
            </Typography>
            <Typography variant="body1">
              Save time and convert as many text-to-speech or speech-to-text content for your blogs.
            </Typography>
            <Grid container spacing={2} mt={'10px'}>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: '100%' }} >
                  <Autocomplete
                    id="languages"
                    options={languageList || []}
                    getOptionLabel={(option) => option.title}
                    // defaultValue={languageList.find(lang => lang.title === 'English')}
                    onChange={(event, value) => handleLanguageChange(value)}
                    renderInput={(params) => <TextField {...params} label="Select Languages" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: '100%' }}  >
                  <Autocomplete
                    id="voices"
                    options={selectedVoice || []}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) =>
                      handleVoiceChange(value)
                    }
                    isOptionEqualToValue={(option, value) => option.name === value.name} // Customize equality test
                    renderInput={(params) => <TextField {...params} label="Select Voice" />}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="content"
                  label="Enter Text To Convert Into Voice"
                  multiline
                  rows={5}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  value={text}
                  onChange={handleTextChange}
                  inputProps={{ maxLength: userData?.user ? 2000 : 500 }}
                />
                <Typography variant="body2" color="textSecondary">
                  {!userData?.user && `Characters left: ${500 - text?.length}`}
                </Typography>
              </Grid>
              {/* <Grid item xs={12}>
                <ReCAPTCHA
                  sitekey="6LeaonQqAAAAABhvJs-KKxGKkQB3Unub7MSPWmvt"
                  onChange={value => setCaptchaValue(value)}
                />
              </Grid> */}
              <Grid item xs={12}>
                <GoogleReCaptchaProvider reCaptchaKey="6LeaonQqAAAAABhvJs-KKxGKkQB3Unub7MSPWmvt">
                  <ReCaptchaComponent />
                </GoogleReCaptchaProvider>
              </Grid>


              <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '100%', marginTop: 2 }}>
                <Box sx={{}}>
                  <Button sx={{ backgroundColor: Colors.Primary, fontWeight: '700' }} onClick={userData?.user ? handleSubmitWithLogin : handleSubmitWithoutLogin} variant="contained" >
                    Convert to Audio
                  </Button>
                </Box>
              </Box>

            </Grid>
          </Grid>
        </Grid>
      </Container>

      <SampleAudios />

      <Footer />

      <ToastContainer />
    </div>
  );
};

export default Home;