import React, { useEffect } from 'react';
import { Container, Grid, Typography, TextField, Button, Box, Backdrop, CircularProgress, Avatar } from '@mui/material';
import { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { SpeechToTextWithoutLogin } from '../apis/WithoutLogin';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserDetail, SpeechToTextWithLogin } from '../apis/WithLoginAPI';
import ReCAPTCHA from 'react-google-recaptcha';
import { Colors } from '../constant/Colors';
import { useNavigate } from 'react-router-dom';
import SampleAudios from '../components/SampleAudios';

const SpeechToText = () => {
    const userData = JSON.parse(localStorage.getItem("loggedUser"))

    const navigate = useNavigate()

    const [file, setFile] = useState(null);
    const [textData, setTextData] = useState()
    const [loading, setLoading] = useState(false)
    const [captchaValue, setCaptchaValue] = useState()
    const [userDetail, setUserDetail] = useState()

    // const handleFileChange = (event) => {
    //     // Handle file change here
    //     setFile(event.target.files[0]);
    // };

    useEffect(() => {
        (async () => {
            if (userData?.user) {
                const res = await getUserDetail(userData?.token)
                setUserDetail(res.data)
            }
        })()
    }, [])


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        // Check if a file is selected
        if (selectedFile) {
            // Get the file extension
            const fileExtension = selectedFile?.name?.split('.').pop().toLowerCase();

            // Check if the file extension is allowed
            if (fileExtension === 'mp3' || fileExtension === 'wav') {
                if (!userData?.user) {
                    const fileSize = selectedFile?.size;
                    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

                    if (fileSize > maxSize) {
                        toast.warning("File size exceeds 2MB. Please select a smaller file.", {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        event.target.value = null;
                    } else {
                        setFile(selectedFile);
                    }
                } else {
                    const fileSize = selectedFile?.size;
                    const maxAllowedSize = 20 * 1024 * 1024;
                    if (fileSize > maxAllowedSize) {
                        toast.warning("File size exceeds 20MB. Please select a smaller file.", {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        event.target.value = null;
                    } else {
                        setFile(selectedFile);
                    }
                }
            } else {
                // File extension is not allowed, show an error
                toast.error("Only .mp3 and .wav files are allowed.", {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                event.target.value = null;
            }
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(textData)
            .then(() => {
                toast.success('Text copied to clipboard!', {
                    position: 'top-right',
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
            })
            .catch((error) => {
                toast.error('Failed to copy text to clipboard!', {
                    position: 'top-right',
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                });
                console.error('Error copying text to clipboard:', error);
            });
    };


    // const handleFileChange = (event) => {
    //     const selectedFile = event.target.files[0];

    //     if (!userData?.user) {
    //         const fileSize = selectedFile?.size; // in bytes
    //         const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    //         if (fileSize > maxSize) {
    //             // File size exceeds 2MB, handle the error here
    //             toast.warning("File size exceeds 2MB. Please select a smaller file.", {
    //                 position: "top-right",
    //                 autoClose: 2500,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "light",
    //             });
    //             event.target.value = null;
    //         } else {
    //             setFile(selectedFile);
    //         }
    //     } else {
    //         const fileSize = selectedFile?.size;
    //         const maxAllowedSize = 10 * 1024 * 1024; // 10MB in bytes
    //         if (fileSize > maxAllowedSize) {
    //             // File size exceeds 2MB, handle the error here
    //             toast.warning("File size exceeds 10MB. Please select a smaller file.", {
    //                 position: "top-right",
    //                 autoClose: 2500,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //                 theme: "light",
    //             });
    //             event.target.value = null;
    //         } else {
    //             setFile(selectedFile);
    //         }
    //     }
    //     // const fileSize = selectedFile?.size; 
    //     // const maxSize = 2 * 1024 * 1024; 

    //     // if (fileSize > maxSize) {
    //     //     toast.warning("File size exceeds 2MB. Please select a smaller file.", {
    //     //         position: "top-right",
    //     //         autoClose: 2500,
    //     //         hideProgressBar: false,
    //     //         closeOnClick: true,
    //     //         pauseOnHover: true,
    //     //         draggable: true,
    //     //         progress: undefined,
    //     //         theme: "light",
    //     //     });
    //     //     event.target.value = null;
    //     // } else {
    //     //     setFile(selectedFile);
    //     // }
    // };

    const handleSubmitWithoutLogin = async () => {
        if (!file) {
            toast.error("Please choose a file", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        if (!captchaValue) { // Assuming captchaValue is the state to store the value of reCAPTCHA
            toast.error("Verify you are not a robot.", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        setLoading(true)
        try {
            const formData = new FormData();
            formData.append("file", file);
            const res = await SpeechToTextWithoutLogin(formData);

            // Convert res.data into a text file
            const blob = new Blob([res.data], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);

            setTextData(res.data);
            setLoading(false);

            // Download the text file
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Textify_Audio_File.txt';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            toast.success(res?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            //   setWithoutLoginData(res.data)
        } catch (error) {
            console.log("🚀 ~ handleSubmit ~ error:", error);
            setLoading(false);
            toast.error("Please select the file", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

    };

    const handleSubmitWithLogin = async () => {
        if (!file) {
            toast.error("Please choose a file", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        if (!captchaValue) { // Assuming captchaValue is the state to store the value of reCAPTCHA
            toast.error("Please confirm you are a human.", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return;
        }
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("file", file);
            const res = await SpeechToTextWithLogin(formData);

            // Convert res.data into a text file
            const blob = new Blob([res.data], { type: 'text/plain' });
            const url = URL.createObjectURL(blob);

            setTextData(res.data);
            setLoading(false);

            // Download the text file
            const a = document.createElement('a');
            a.href = url;
            a.download = 'Textify_Audio_File.txt';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            toast.success(res?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            //   setWithoutLoginData(res.data)
        } catch (error) {
            console.log("🚀 ~ handleSubmit ~ error:", error);
            setLoading(false);
            toast.error("Please select the file", {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleStripeForm = () => {
        navigate('/stripe')
    }

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Header />
            <Container>
                <Grid container alignItems="center" justifyContent={'center'}>
                    {userDetail?.plan == 'Basic' &&
                        <Grid item md={10} sx={{ cursor: 'pointer' }}>
                            <Avatar variant='rounded' sx={{ width: '100%', height: '200px' }} onClick={handleStripeForm} />
                        </Grid>
                    }
                    <Grid item xs={12} md={10} sx={{ marginTop: '5%', backgroundColor: 'white', padding: 4, borderRadius: '10px', marginBottom: 5 }}>
                        <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 2 }}>Fastest Speech-To-Text Conversion.</Typography>
                        <Typography variant="body1">Save time and convert as many audio files into text for your blogs</Typography>
                        <Grid item xs={12} md={12} sx={{ marginTop: '10px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        type="file"
                                        name="file"
                                        id="file"
                                        onChange={handleFileChange}
                                    // label="Upload Audio File"
                                    />
                                    <Typography variant="body2">For {!userData?.user ? "free" : "package"} plan, only {!userData?.user ? "2MB" : "20MB"} file is allowed to upload</Typography>
                                </Grid>
                                {!textData &&
                                    <Grid item xs={12}>
                                        <ReCAPTCHA
                                            sitekey="6Le6iL4pAAAAAL27A1ZYF8HjYqsMhDMQgrzvrA1K"
                                            onChange={value => setCaptchaValue(value)}
                                        />
                                    </Grid>
                                }

                                {textData &&
                                    <>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: 1, }}>
                                            <Typography sx={{ marginLeft: 2, fontSize: '22px', fontWeight: '700', }} variant="body2">Converted</Typography>
                                            <Button
                                                sx={{ backgroundColor: Colors.Primary, fontWeight: '700' }}
                                                variant="contained"
                                                onClick={copyToClipboard}
                                            >
                                                Copy Text
                                            </Button>
                                        </Box>
                                        <Box sx={{ border: 1, width: '100%', ml: 2, py: 2, marginTop: 1, borderRadius: '5px', borderColor: 'lightgrey' }} >
                                            <Typography sx={{ marginLeft: 2 }} variant="body2">{textData}</Typography>
                                        </Box>
                                    </>
                                }
                                {/* {textData &&
                                    <Grid item xs={12}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                            <Button
                                                sx={{ backgroundColor: Colors.Primary, fontWeight: '700', mr: 2 }}
                                                variant="contained"
                                                onClick={copyToClipboard}
                                            >
                                                Copy Text
                                            </Button>
                                        </Box>
                                    </Grid>
                                } */}
                                {!textData && <Grid item xs={12} className="text-right">
                                    <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', width: '100%' }}>
                                        <Button sx={{ backgroundColor: Colors.Primary, fontWeight: '700' }} variant="contained" onClick={userData?.user ? handleSubmitWithLogin : handleSubmitWithoutLogin}>Convert to Text</Button>
                                    </Box>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <SampleAudios />
            <Footer />
            <ToastContainer />
        </div>
    );
};

export default SpeechToText;
