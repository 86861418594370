import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Box, Container, Grid } from "@mui/material";
import Header from "../components/Header";
import { Colors } from "../constant/Colors";
import { GetUpgradePackage, GetVerifyPayment } from "../apis/WithLoginAPI";
import { useNavigate } from "react-router-dom";

const CheckoutForm = () => {
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("loggedUser"));

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  //   useEffect(() => {
  //     (async () => {
  //       const res = await GetVerifyPayment(userData?.token);
  //       console.log("Response Stripe", res);
  //     })();
  //   }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // if (elements == null) {
    //   return;
    // }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    try {
      const res = await GetVerifyPayment(userData?.token);
      console.log("Response from GetVerifyPayment:", res);

      const data = await res.data;
      console.log("🚀 ~ handleSubmit ~ data:", data);

      let abc = { client_secret: data };

      const { client_secret: clientSecret } = abc;

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: "http://localhost:3000/verifypayment",
        },
      });

      

      if (error) {
        setErrorMessage(error.message);
      } else {
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setErrorMessage("An error occurred while processing your payment.");
    }
  };

  //   const handleSubmit = async (event) => {
  //     event.preventDefault();

  //     if (elements == null) {
  //       return;
  //     }

  //     // Trigger form validation and wallet collection
  //     const { error: submitError } = await elements.submit();
  //     if (submitError) {
  //       // Show error to your customer
  //       setErrorMessage(submitError.message);
  //       return;
  //     }

  //     // Create the PaymentIntent by calling the serverless function
  //     const res = await GetVerifyPayment(userData?.token);
  //     console.log("Response from GetVerifyPayment:", res);

  //     const data = await res.data;
  //     console.log("🚀 ~ handleSubmit ~ data:", data);

  //     let abc = { client_secret: data };

  //     const { client_secret: clientSecret } = abc;

  //     // Confirm the PaymentIntent
  //     const { error } = await stripe.confirmCardPayment(clientSecret, {
  //       payment_method: {
  //         card: elements.getElement(PaymentElement),
  //         billing_details: {
  //           // Add appropriate billing details
  //           name: "Test User",
  //         },
  //       },
  //     });

  //     if (error) {
  //       console.log("🚀 ~ handleSubmit ~ error:", error)
  //       setErrorMessage(error.message);
  //     } else {
  //       // Payment succeeded
  //       console.log("Payment succeeded!");
  //       const formData = new FormData();
  //       formData.append("plan", "Monthly");
  //       const response = await GetUpgradePackage(userData.token, formData);
  //       console.log("Response from GetUpgradePackage:", response);

  //       navigate("/");
  //     }
  //   };

  // const res = await GetVerifyPayment(userData?.token);
  // console.log("Response Stripe", res);

  // // Create the PaymentIntent and obtain clientSecret from your server endpoint

  // const { client_secret: clientSecret } = await res.json();

  // const { error } = await stripe.confirmPayment({
  //   //`Elements` instance that was used to create the Payment Element
  //   elements,
  //   clientSecret,
  //   confirmParams: {
  //     return_url: "http://localhost:3000/",
  //   },
  // });

  // if (error) {
  //   setErrorMessage(error.message);
  // } else {
  //   const formData = new FormData();
  //   formData.append("plan", "Monthly");
  //   const response = await GetUpgradePackage(userData.token, formData);
  //   console.log("🚀 ~ handleSubmit ~ res:", response);

  //   navigate("/");
  // }
  //   };

  //   const handleSubmit = async (event) => {
  //     event.preventDefault();

  //     if (elements == null) {
  //       return;
  //     }

  //     // Trigger form validation and wallet collection
  //     const { error: submitError } = await elements.submit();
  //     if (submitError) {
  //       // Show error to your customer
  //       setErrorMessage(submitError.message);
  //       return;
  //     }

  //     // Create the PaymentIntent by calling the serverless function
  //     const response = await fetch("https://twx.nxpt.cc/api/v2/Login", {
  //       method: "POST",
  //     });

  //     const data = await response.json();
  //     const { client_secret: clientSecret } = data;

  //     // Confirm the PaymentIntent
  //     const { error } = await stripe.confirmCardPayment(clientSecret, {
  //       payment_method: {
  //         card: elements.getElement(PaymentElement),
  //         billing_details: {
  //           // Add appropriate billing details
  //           name: "Test User",
  //         },
  //       },
  //     });

  //     if (error) {
  //       setErrorMessage(error.message);
  //     } else {
  //       // Payment succeeded
  //       console.log("Payment succeeded!");
  //     }
  //   };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button
          style={{
            backgroundColor: Colors.Primary,
            color: "white",
            borderRadius: "5px",
            marginTop: "40px",
            width: "100%",
            height: "40px",
          }}
          type="submit"
          disabled={!stripe || !elements}
        >
          Pay Now
        </button>
      </Box>
      {/* Show error message to your customers */}
      {/* {errorMessage && <div>{errorMessage}</div>} */}
    </form>
  );
};

const stripePromise = loadStripe(
  "pk_test_51Nkfk3ErW7mwOoh5JtRxJR3X8AOUTyQTeWMiDBnBHo7CpqFuGtjtuiG7r7UdiWYreGnJWt6bTF54c079JfYfj9ph00ZHSAyXOI"
);

const options = {
  mode: "payment",
  amount: 1099,
  currency: "usd",
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

const Stripe = () => (
  <>
    <Header />
    <Container>
      <Grid container justifyContent="center">
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            marginTop: 5,
            backgroundColor: "white",
            padding: 4,
            borderRadius: "10px",
          }}
        >
          <Elements stripe={stripePromise} options={options}>
            <CheckoutForm />
          </Elements>
        </Grid>
      </Grid>
    </Container>
  </>
);

export default Stripe;
