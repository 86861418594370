import { AxiosInstanceWithoutLogin } from "./AxiosInstanceWithoutLogin";


export const TextToSpeechWithoutLogin = async (data) => {
    const response = await AxiosInstanceWithoutLogin.post('/textToAudioOpen', data);
    return response.data;
};

export const SpeechToTextWithoutLogin = async (data) => {
    const headers = AxiosInstanceWithoutLogin.defaults.headers;
    headers["Content-Type"] = "multipart/form-data";
    const response = await AxiosInstanceWithoutLogin.post('/audioToTextOpen', data);
    return response.data;
};

export const getAllVoiceByLang = async (lang) => {
    const response = await AxiosInstanceWithoutLogin.get('/getLanguagesVoices/' + lang);
    return response.data;
};

