import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Header from '../components/Header';
import { Container, Grid, Typography, TextField, Button, IconButton, InputAdornment, Box } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Colors } from '../constant/Colors';
import { loginValidationSchema } from '../constant/formikValidation';
import { loginUser } from '../apis/WithLoginAPI';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        email: '',
        password: ''
    };

    const handleLogin = async (values) => {
        try {
            const res = await loginUser(values)
            toast.success(res?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setTimeout(() => {
                navigate("/")
            }, 1500);
        } catch (error) {
            console.log("🚀 ~ file: Login.jsx:30 ~ handleLogin ~ error:", error)
            toast.error(error?.response?.data?.message, {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const handleTogglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    return (
        <>
            <Header />
            <Container>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={6} sx={{ marginTop: '8%', marginBottom: '8%', backgroundColor: 'white', padding: 4, borderRadius: '10px' }}>
                        <Typography sx={{ textAlign: 'center', marginBottom: 2, fontSize: '28px', fontWeight: '700', }}>
                            Login
                        </Typography>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={loginValidationSchema}
                            onSubmit={handleLogin}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <Field
                                        as={TextField}
                                        type="email"
                                        name="email"
                                        label="Email"
                                        fullWidth
                                        margin="normal"
                                    />
                                    <ErrorMessage name="email" render={msg => <div style={{ color: 'red', fontSize: '14px' }}>{msg}</div>} />

                                    <Field
                                        as={TextField}
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        label="Password"
                                        fullWidth
                                        margin="normal"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <ErrorMessage name="password" render={msg => <div style={{ color: 'red', fontSize: '14px' }}>{msg}</div>} />

                                    <Typography sx={{
                                        fontSize: '12px',
                                        '&:hover': {
                                            cursor: 'pointer',
                                        },
                                        textDecorationLine: 'underline',
                                        color: Colors.Primary,
                                        fontWeight: '300',
                                        textAlign: 'end'
                                    }} onClick={() => navigate('/forgotpassword')}>Forgot Password?</Typography>

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={isSubmitting}
                                        sx={{ marginTop: 2, backgroundColor: !isSubmitting ? Colors.Primary : Colors.white, '&:hover': { backgroundColor: Colors.Primary }, fontSize: '18px', fontWeight: '700', }}
                                    >
                                        {isSubmitting ? 'Logging in...' : 'Login'}
                                    </Button>
                                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2, justifyContent: 'center' }} >
                                        <Typography sx={{}}>Don' have an account</Typography>
                                        <Link
                                            to={'/signup'}
                                            disabled={isSubmitting}
                                            style={{ fontSize: '14px', fontWeight: '700', '&:hover': { backgroundColor: 'white' }, marginLeft: '5px', }}
                                        >
                                            SignUp
                                        </Link>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
            <ToastContainer />
        </>
    );
};

export default Login;