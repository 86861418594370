import axios from 'axios';

const user = JSON.parse(localStorage.getItem("loggedUser"));
let token = user ? user.token : '';

export const AxiosInstanceWithoutLogin = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "Access-Control-Allow-Origin": "*",
        // 'Authorization': "Bearer " + token
    },
});