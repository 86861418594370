import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DownloadPage from "./DownloadAudioFile";
import ForgotPassword from "./ForgotPassword";

// Components
import Home from "./Home";
import Login from "./Login";
import OTPView from "./OTPView";
import SignUp from "./SignUp";
import SpeechToText from "./SpeechToText";
import Stripe from "./Stripe";
import UpdatePassword from "./UpdatePassword";
import VerifyPayment from "./VerifyPayment";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export const RoleBasedRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<Home />} />
      <Route index path="/speechtotext" element={<SpeechToText />} />
      <Route index path="/login" element={<Login />} />
      <Route index path="/signup" element={<SignUp />} />
      <Route index path="/otp" element={<OTPView />} />
      <Route index path="/forgotpassword" element={<ForgotPassword />} />
      <Route index path="/updatepassword" element={<UpdatePassword />} />
      <Route index path="/download" element={<DownloadPage />} />
      <Route index path="/stripe" element={<Stripe />} />
      <Route index path="/verifypayment" element={<VerifyPayment />} />
    </Routes>
  );
};

export function AllRoutes() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <RoleBasedRoutes />
      </BrowserRouter>
    </ThemeProvider>
  );
}
